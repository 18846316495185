$icon-font-path: "../fonts/";
// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
// endbower
@import "_variables.scss";
@import "_fonts.scss";
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* Space out content a bit */

html {
  font-size: 62.5%;
}

body {
  padding: 0;
  margin: 0;
  font-family: $f_ch_R;
  @include font-size(1.6);
  min-height: 100vh;

  box-sizing: border-box;
}

a {
  text-decoration: none;
}

main {
  background-color: $white;
  color: $p_white;
}

.container-fluid {
  padding: 0 130px;
}

@media #{$media_0_1199} {
  .container-fluid {
    padding: 0 100px;
  }
}

@media #{$media_0_991} {
  .container-fluid {
    padding: 0 15px;
  }
}

//SELECTS
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: $f_nt_R;
  @include font-size(3.3);
}
/*hide original SELECT element:*/
.custom-select select {
  display: none !important;
}
.select-selected {
  background-color: $grey;
  color: $white;
  @include font-size(1.6);
  @include transition(all 0.2s ease-in);
  padding: 25px;
  font-family: $f_nt_R;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  content: "\f0d7";
  font: normal normal normal 24px/1 FontAwesome;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition(all 0.2s ease-in);
}

/*style the items (options), including the selected item:*/
.select-items div {
  border: 1px solid transparent;
  border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
  font-family: $f_nt_R;
  @include font-size(1.8);
  color: $white;
  padding: 8px 16px;
  margin: 0;
  cursor: pointer;
  @include transition(all 0.15s ease-in);
}

/*style items (options):*/
.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 320px;
  overflow-y: auto;
  background-color: rgba(137, 126, 126, 0.92);
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}
.select-items div:hover,
.same-as-selected {
  background-color: rgba(137, 126, 126, 0.92) !important;
}

header {
  background-color: $grey;
  padding-bottom: 40px;
  .container-fluid {
    .flag {
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      a {
        margin-right: 8px;
        width: 20px;
        img {
          width: 100%;
        }
      }
    }
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: -8px;
      > a {
        flex: 1;
        img {
          // width: 70%;
          width: 265px;
        }
      }
      .social-links {
        display: flex;
        justify-content: flex-end;
        align-self: center;
        a {
          color: $white;
          &:hover {
            transition: all ease-in-out 0.1s;
            color: $accent;
          }
          &:first-child {
            margin: 0 25px;
          }
          i {
            @include font-size(2.2);
          }
        }
      }
      .navbar {
        border: none;
        border-radius: 0px;
        margin-bottom: 0;
        margin-left: 30px;
        margin-right: 30px;
        flex: 2;
        .navbar-collapse {
          padding-left: 0;
          padding-right: 0;
        }
        .navbar-header {
          .navbar-toggle {
            border-color: #fff;
            .icon-bar {
              background: #fff;
            }
          }
        }
        .nav {
          font-family: $f_nt_R;
          @include font-size(1.2);
          text-transform: uppercase;
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          width: 100%;
          > .active {
            > a {
              background-color: transparent;
              color: $accent;
              &:hover {
                transition: all ease-in-out 0.1s;
                background-color: transparent;
              }
            }
          }
          > li {
            position: relative;
            transition: all ease-in-out 0.1s;
            &:hover > ul,
            &:focus > ul,
            &.open > ul {
              display: block;
              opacity: 1;
              z-index: 900;
              transition: all ease-in-out 0.1s;
            }
            &:hover > a {
              transition: all ease-in-out 0.1s;
              color: $accent;
              background-color: transparent;
            }
            &:hover {
              transition: all ease-in-out 0.1s;
              background-color: transparent;
            }
          }
          > li > a {
            padding: 25px 7px;
            color: $p_white;
            &:focus {
              background-color: transparent;
            }
          }
          > li > ul {
            position: absolute;
            background-color: rgba(137, 126, 126, 0.92);
            left: 0px;
            list-style-type: none;
            padding-bottom: 18px;
            padding-top: 18px;
            padding-left: 40px;
            display: none;
            opacity: 0;
            z-index: 999999999000;
            > li {
              padding: 10px 50px 5px 0px;
              width: 550px;
            }
            > li > a {
              display: block;
              width: 100%;
              color: $p_white;
              padding: 0 0 0 20px;
              transition: all ease-in-out 0.1s;
              &:hover {
                color: $p_white;
                text-decoration: none;
                span {
                  border-bottom: 1px solid $p_white;
                }
              }
            }
            .sous-active {
              list-style-image: url(../images/puce.png);
              z-index: 800;
              vertical-align: middle;
              span {
                border-bottom: 1px solid $p_white;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_992_1199} {
  header {
    .container-fluid {
      .header-container {
        > a {
          flex-basis: 100%;
        }
      }
    }
  }
}

@media #{$media_0_1199} {
  header {
    .container-fluid {
      .header-container {
        flex-wrap: wrap;
        > a {
          text-align: center;
          margin-bottom: 30px;
          img {
            // width: 60%;
            width: 265px;
          }
        }
        .navbar {
          .nav {
            &:before,
            &:after {
              content: none;
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_991} {
  header {
    .container-fluid {
      .flag {
        margin-left: 50px;
      }
    }
  }
}

@media #{$media_0_767} {
  header {
    position: relative;
    padding-bottom: 4px;
    .container-fluid {
      .flag {
        margin-top: 5px;
        margin-bottom: 5px;
        a {
          width: 15px;
        }
      }
      .header-container {
        > a {
          flex-basis: min-content;
          text-align: left;
          padding-left: 30px;
          margin: 0;
          img {
            width: 70%;
            max-width: 200px;
          }
        }
        .navbar {
          position: unset;
          flex: inherit;
          .navbar-collapse {
            border: none;
            box-shadow: none;
            position: absolute;
            z-index: 9;
            background-color: #3a3a3a;
            width: 100%;
            left: 0px;
            top: 0px;
          }
          .navbar-header {
            margin-top: -8px;
            margin-right: -43px;
            .navbar-toggle {
              float: none;
              border: none;
            }
          }
          .nav {
            flex-direction: column;
            // align-items: center;
            font-family: $f_reg_B;
            @include font-size(2);
            text-transform: none;
            margin: 0;
            position: relative;
            padding: 15px 35px;
            text-align: center;
            button {
              position: absolute;
              top: 20px;
              right: 0;
              z-index: 999;
              span {
                transform: rotate(45deg);
                background-color: white;
                margin: 0;
                &:first-child {
                  transform: rotate(-45deg) translate(-1px, 1px);
                }
              }
            }
            > a {
              text-align: center;
              img {
                width: 70%;
              }
            }
            > li + li {
              border-top: 1px solid #707070;
            }
            > li > ul {
              position: relative;
              padding-left: 30px;
              > li {
                width: 100%;
                padding-bottom: 10px;
              }
            }
          }
        }
        .social-links {
          display: none;
        }
      }
    }
  }
}

/* =================== accueil =================== */

section.home-page {
  position: relative;
  > a {
    width: 100%;
    height: 100vh;
    position: relative;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .img-filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      font-family: $f_reg_B;
      @include font-size(3);
      // text-transform: uppercase;
    }
    &:not(:nth-last-child(2)) {
      margin-bottom: 10px;
    }
    .scroll-picto {
      position: absolute;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      @include font-size(4);
      color: $white;
    }
  }
  .scroll-indicators-container {
    position: absolute;
    top: 0;
    right: 25px;
    width: 70px;
    height: 100%;
    .scroll-indicators {
      width: 100%;
      height: 100vh;
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      a {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid $white;
        position: relative;
        &:after {
          content: "";
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $white;
          border-radius: 50%;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &:not(.active) {
          border: none;
        }
      }
    }
  }
}

@media #{$media_0_1199} {
  section.home-page {
    > a {
      p {
        @include font-size(4);
      }
    }
  }
}

@media #{$media_0_767} {
  section.home-page {
    > a {
      height: 90vw;
      background-attachment: initial;
      p {
        @include font-size(3.4);
        text-align: center;
        top: calc(100% / 3);
        width: 90%;
      }
      .scroll-picto {
        @include font-size(3);
        bottom: 10px;
      }
    }
    .scroll-indicators-container {
      display: none;
    }
  }
}

/* =================== product-list =================== */

.product-list-title {
  padding: 80px;
  .container-fluid {
    display: flex;
    justify-content: center;
  }
}
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-family: $f_nt_R;
    @include font-size(2);
    color: $grey;
    text-transform: uppercase;
    display: block;
    text-align: center;
  }
  .title-decoration {
    width: calc(100% + 100px);
    border-top: 1px solid #707070;
    display: flex;
    justify-content: center;
    .triangle {
      background-color: $grey;
      width: 33px;
      height: 26px;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
    }
  }
}

/* =================== search form =================== */

#form {
  padding: 0;
  form.form-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button,
    .select-selected {
      background-color: $grey;
      color: $white;
      font-family: $f_nt_R;
      @include font-size(1.6);
      padding: 15px;
      padding-right: 100px;
      margin: 0 5px;
      border: none;
      border-radius: 0;
      height: auto;
      white-space: nowrap;
      &::placeholder {
        color: $white;
      }
    }
    .custom-select {
      flex: 1;
    }
    button {
      flex: 2;
      padding: 15px 50px;
    }
  }
  h2 {
    font-family: $f_nt_R;
    @include font-size(2);
    color: $grey;
    text-transform: uppercase;
  }
  form.form-order {
    display: flex;
    align-items: center;
    .select-selected {
      @include font-size(2);
      padding: 6px 45px 6px 30px;
      margin-left: 15px;
      &::after {
        padding: 6px;
      }
    }
  }
}

@media #{$media_0_1199} {
  #form {
    form.form-search {
      flex-wrap: wrap;
      button,
      .select-selected {
        margin-top: 10px;
      }
      button {
        flex-basis: 100%;
      }
    }
    h2 {
      @include font-size(1.8);
    }
    form.form-order {
      .select-selected {
        @include font-size(1.6);
      }
    }
  }
}

section.resultats {
  background-image: url("/images/product-list.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 30px;
  .container-fluid {
    .title-container {
      background-color: $white;
      display: flex;
      justify-content: center;
      padding: 30px;
      .title {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          width: max-content;
          position: relative;
          margin: 0;
          text-align: center;
          font-family: $f_nt_R;
          @include font-size(2.6);
          text-transform: uppercase;
          margin-bottom: 10px;
          &:before,
          &:after {
            content: "";
            width: 20px;
            height: 1px;
            background-color: #707070;
            position: absolute;
            top: 102%;
            transform: translateY(-50%);
          }
          &:before {
            right: 105%;
          }
          &:after {
            left: 105%;
          }
        }
        h3 {
          margin: 0;
          text-align: center;
          font-family: $f_nt_R;
          @include font-size(2);
          text-transform: uppercase;
        }
      }
    }
    .content {
      background-color: $white;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .annonce {
        flex-basis: calc(50% - 50px);
        margin-bottom: 100px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .annonce-summary {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba(255, 255, 255, 0.6);
          display: flex;
          flex-wrap: wrap;
          color: #000;
          padding: 15px;
          h2 {
            flex-basis: 100%;
            font-family: $f_nt_R;
            @include font-size(2.4);
            margin: 0;
            margin-bottom: 5px;
          }
          p {
            margin-right: 30px;
            @include font-size(1.4);
            font-family: $f_ch_R;
            &:last-child {
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_1199} {
  section.resultats {
    .container-fluid {
      .content {
        .annonce {
          flex-basis: calc(50% - 30px);
          margin-bottom: 60px;
          .annonce-summary {
            h2 {
              @include font-size(1.8);
            }
            p {
              &:last-child {
                flex-basis: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_991} {
  section.resultats {
    .container-fluid {
      .content {
        .annonce {
          .annonce-summary {
            p {
              @include font-size(1.2);
              &:last-child {
                flex-basis: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_767} {
  section.resultats {
    .container-fluid {
      .content {
        .annonce {
          flex-basis: 100%;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  .etat {
    font-family: $f_ch_M;
    @include font-size(1.6);
    color: #000;
    margin: 0;
    margin-right: 30px;
  }
  .liens {
    display: flex;
    justify-content: flex-end;
    > * {
      font-family: $f_reg_B;
      @include font-size(1.8);
      color: #000000;
      &:after {
        content: "|";
        color: #707070;
        @include font-size(1.2);
        vertical-align: 2px;
        padding: 0 2px;
      }
    }
    a[rel="next"] {
      &:after {
        content: "";
      }
      + a {
        display: none;
      }
    }
    a[rel="start"] {
      display: none;
    }
    a {
      transition: all ease-in-out 0.1s;
      &:hover {
        text-decoration: none;
        color: $accent;
      }
    }
    strong {
      color: #707070;
    }
  }
}

section.retour {
  margin-top: 30px;
  margin-bottom: -50px;
  .content {
    .btn-retour-container {
      padding: 0 30px;
      .btn-retour {
        background-color: #3a3a3a;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #fff;
        width: 40px;
        height: 40px;
        transition: all ease-in-out 0.1s;
        i {
          @include font-size(2);
          margin-left: -2px;
        }
        &:hover {
          background-color: transparent;
          border: 1px solid #3a3a3a;
          text-decoration: none;
          color: #3a3a3a;
        }
      }
    }
  }
}

/* =================== Product detail =================== */
section.product-detail {
  background-image: url("/images/product-list.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 30px;
  .container-fluid {
    padding: 0 50px;
  }
  .content {
    background-color: $white;
    .btn-retour-container {
      padding: 0 30px;
      .btn-retour {
        background-color: #3a3a3a;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #fff;
        width: 40px;
        height: 40px;
        transition: all ease-in-out 0.1s;
        i {
          @include font-size(2);
          margin-left: -2px;
        }
        &:hover {
          background-color: transparent;
          border: 1px solid #3a3a3a;
          text-decoration: none;
          color: #3a3a3a;
        }
      }
    }
    .details {
      padding: 60px 30px 80px;
      .slider-container {
        display: flex;
        flex-direction: column;
        .product-slider {
          display: flex;
          .thumbnails {
            width: 27%;
            display: flex;
            flex-direction: column;
            .nb-items {
              display: flex;
              align-items: baseline;
              margin-bottom: 6px;
              img {
                width: 52px;
                margin-right: 10px;
              }
              p {
                color: #3a3a3a;
                font-family: $f_reg_B;
                @include font-size(2.4);
              }
            }
            .thumbnails-grid-scroll-zone {
              overflow-y: auto;
              max-height: calc(70vh - 20px);
              .thumbnails-grid {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .item {
                  width: calc(50% - 8px);
                  height: calc(70vh / 4);
                  margin-bottom: 16px;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                  &.video {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                      width: 70px;
                      max-width: 50%;
                      height: auto;
                      object-fit: unset;
                    }
                  }
                }
              }
            }
          }

          .carousel {
            border: none !important;
            position: relative;
            margin-top: 10px;
            padding: 40px;
            flex: 1;
            .carousel-inner {
              height: 42vw;
              iframe {
                width: 100% !important;
              }
            }
            .item {
              margin: 0;
              height: 100%;
              img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                margin: auto;
              }
            }
            .caption {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              background-color: rgba(58, 58, 58, 0.6);
              color: $white;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 30px;
              &.responsive-content {
                display: none;
              }
              .txt {
                flex: 5;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
              }
              p {
                margin: 0;
                font-family: $f_reg_B;
                @include font-size(1.6);
                padding: 0 7px;
                text-transform: uppercase;
                &:first-child {
                  flex: 3;
                  flex-basis: 100%;
                  padding: 0 15px 0 0;
                }
              }
              a {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #69140e;
                border: none;
                padding: 15px;
                color: $white;
                i {
                  @include font-size(2.7);
                  margin-right: 10px;
                }
                p {
                  @include font-size(1.5);
                  display: contents;
                }
              }
            }
            .carousel-control {
              display: flex;
              align-items: center;
              padding: 50px;
              &.right {
                justify-content: flex-end;
              }
              i {
                @include font-size(3);
                color: $white;
              }
            }
            .highlights {
              margin-top: 30px;
              display: flex;
              justify-content: space-around;
              align-items: center;
              > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: $f_reg_B;
                @include font-size(2.1);
                &.product-categorie-container {
                  align-items: flex-start;
                }
                img {
                  width: max-content;
                  max-width: 40px;
                }
                .ref-product {
                  color: #b6b6b6;
                  @include font-size(1.7);
                }
              }
            }
          }
        }
      }
      > .description {
        padding: 0px 140px 50px;
        text-align: center;
      }
      .title-container {
        display: flex;
        justify-content: center;
        .title {
          position: relative;
          h3 {
            font-family: $f_reg_B;
            @include font-size(2.4);
            display: block;
            background-color: $white;
            margin: 0;
            position: relative;
            z-index: 66;
            padding: 0 5px;
          }
          &:before {
            content: "";
            width: calc(100% + 400px);
            height: 1px;
            background-color: #707070;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .energy-container {
        display: flex;
        justify-content: space-between;
        padding: 0 200px;
        margin-top: 50px;
        > div {
          flex: 1;
          margin: 0 10px;
          display: flex;
          flex-direction: column;
          h5 {
            font-family: $f_ch_B;
            @include font-size(2);
            margin-bottom: 30px;
          }
          p {
            font-family: $f_ch_R;
            @include font-size(1.6);
            margin-bottom: 30px;
          }
          img {
            width: max-content;
          }
          &.electricity {
            img {
              align-self: flex-end;
            }
          }
        }
      }
      .mentions {
        margin-top: 30px;
        p {
          font-family: $f_reg_B;
          @include font-size(1.6);
        }
      }
      .actions {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        a {
          background-color: #3a3a3a;
          color: $white;
          font-family: $f_reg_B;
          @include font-size(2);
          width: 360px;
          padding: 20px;
          border-radius: 0;
          border: 0;
          flex: 1;
          margin-left: 50px;
          white-space: normal;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          padding: 10px;
          transition: all ease-in-out 0.1s;
          &:first-child {
            margin-left: 0;
          }
          &:hover {
            background-color: $accent;
          }
        }
      }
    }
    #versformulaire_bien {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h2 {
        text-align: center;
        border-bottom: 1px solid #707070;
        font-family: $f_reg_B;
        @include font-size(3.5);
        display: inline-block;
        padding-bottom: 15px;
        margin-bottom: 30px;
      }
      .main-contact-p {
        width: 810px;
        .contact-p-info-comp {
          font-family: $f_ch_R;
          @include font-size(1.2);
        }
        form {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 100px;
          .inputs {
            display: flex;
            flex-direction: column;
            flex: 1;
            input {
              margin-bottom: 20px;
            }
          }
          textarea {
            flex: 1;
            margin-bottom: 60px;
            margin-left: 10px;
          }
          button {
            flex-basis: 100%;
            text-transform: uppercase;
            font-family: $f_nt_R;
            @include font-size(2);
          }
        }
      }
    }
  }
}

@media #{$media_0_1199} {
  section.product-detail {
    .content {
      .details {
        .slider-container {
          .product-slider {
            .thumbnails {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media #{$media_0_991} {
  section.product-detail {
    .content {
      .details {
        .slider-container {
          .product-slider {
            .thumbnails {
              display: none;
            }

            .carousel {
              padding: 0;
              .caption {
                &:not(.responsive-content) {
                  display: none;
                }
                &.responsive-content {
                  display: block;
                  position: unset;
                  background-color: $white;
                  color: #3a3a3a;
                  flex-direction: column;
                  justify-content: flex-end;
                  align-items: flex-start;
                }
                p {
                  flex-basis: 100%;
                  &:first-child {
                    max-width: 100%;
                    margin-bottom: 15px;
                  }
                }
                a {
                  margin-top: 15px;
                }
              }
              .carousel-indicators {
                bottom: 6%;
              }
              .highlights {
                flex-wrap: wrap;
                margin-top: 0;
                > div {
                  &.product-categorie-container {
                    flex-basis: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    padding: 15px 40px 45px;
                  }
                }
              }
            }
          }
        }
        > .description {
          padding: 50px;
        }
        .title-container {
          .title {
            h3 {
              @include font-size(2);
            }
            &:before {
              width: calc(100% + 60px);
            }
          }
        }
        .energy-container {
          padding: 0;
        }
      }
      #versformulaire_bien {
        .main-contact-p {
          width: auto;
          padding: 0 15px;
        }
      }
    }
  }
}

@media #{$media_0_767} {
  section.product-detail {
    .container-fluid {
      padding: 0 15px;
    }
    .content {
      .details {
        padding: 50px 15px;
        .slider-container {
          .product-slider {
            .carousel {
              .highlights {
                flex-direction: column;
                align-items: flex-start;
                > div {
                  @include font-size(1.6);
                  padding: 15px 40px 15px;
                  &.product-categorie-container {
                    flex-direction: column;
                  }
                  img {
                    width: 40px;
                  }
                }
              }
            }
          }
        }
        .title-container {
          .title {
            max-width: calc(100% - 50px);
            h3 {
              text-align: center;
            }
          }
        }
        .energy-container {
          flex-wrap: wrap;
          > div {
            h5 {
              @include font-size(1.6);
            }
            p {
              @include font-size(1.4);
            }
            img {
              align-self: center;
              max-width: 100%;
            }
            &.electricity {
              img {
                align-self: center;
              }
            }
          }
        }
        .actions {
          flex-direction: column;
          a {
            width: 100%;
            margin: 15px auto !important;
          }
        }
      }
      #versformulaire_bien {
        h2 {
          @include font-size(2);
        }
        .main-contact-p {
          form {
            flex-direction: column;
            textarea {
              margin-left: 0;
              min-height: 150px;
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

/* =================== Contact =================== */
section.contact {
  padding-top: 50px;
  .container-fluid {
    &:first-child {
      display: flex;
      justify-content: center;
    }
    p {
      font-family: $f_reg_B;
      @include font-size(1.8);
      text-align: center;
      margin: 15px 0;
    }

    form {
      margin: 70px auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 840px;
      .alert {
        width: 100%;
      }
      .input-group {
        width: 100%;
        margin-bottom: 30px;
        &.little {
          width: calc(50% - 5px);
        }
        label {
          display: block;
        }
        input,
        textarea {
          width: 100%;
        }
        &:nth-last-child(2) {
          text-align: center;
        }
      }
      button {
        background-color: transparent;
        border: 1px solid #707070;
        border-radius: 0;
        color: #3a3a3a;
        font-family: $f_nt_R;
        @include font-size(2);
      }
    }
  }
}

@media #{$media_0_991} {
  section.contact {
    .container-fluid {
      form {
        width: auto;
      }
    }
  }
}

@media #{$media_0_767 } {
  section.contact {
    .container-fluid {
      form {
        .input-group {
          &.little {
            width: 100%;
          }
        }
      }
    }
  }
}

input,
textarea,
button {
  background-color: #f7f7f7;
  border: 1px solid #dfdfdf;
  color: #3a3a3a;
  padding: 10px;
  @include font-size(1.3);
  font-family: $f_ch_R;
}

.textes {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $f_reg_B;
  }
}

section.page-redac {
  background-image: url("/images/product-list.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-top: 30px;
  .container-fluid {
    &:before {
      content: none;
    }
  }
  .content {
    background-color: $white;
    padding: 30px;
  }
}

/* =================== Categories =================== */

section.categories {
  .carousel {
    width: 100%;
    height: 100vh;
    .carousel-inner {
      width: 100%;
      height: 100%;
      .item {
        width: 100%;
        height: 100%;
        a {
          width: 100%;
          height: 100%;
          transition: all ease-in-out 0.1s;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          h2 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-family: $f_reg_B;
            @include font-size(4);
            text-align: center;
            color: white;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    .carousel-control {
      display: flex;
      align-items: center;
      padding: 50px;
      &.right {
        justify-content: flex-end;
      }
      i {
        @include font-size(3);
        color: $white;
      }
    }
  }
}

@media #{$media_0_767 } {
  section.categories {
    .carousel {
      height: 90vw;
      .carousel-inner {
        .item {
          a {
            h2 {
              @include font-size(2.5);
              margin: 0;
              max-width: 62vw;
              text-align: left;
              width: max-content;
            }
          }
        }
      }
      .carousel-control {
        padding: 20px;
      }
    }
  }
}

/* =================== Agency =================== */
section.agency {
  .title-container {
    margin: 50px auto;
  }
  .skills {
    display: flex;
    justify-content: space-between;
    > div {
      width: calc(100% / 3 - 5px);
      img {
        width: 100%;
        height: 780px;
        max-height: 50vh;
        object-fit: cover;
      }
      h1 {
        font-family: $f_reg_B;
        @include font-size(2);
        text-transform: uppercase;
        text-align: left;
      }
      p {
        font-family: $f_ch_R;
        @include font-size(1.4);
        text-align: left;
        width: 80%;
      }
    }
  }
  hr {
    margin: 100px auto;
    width: 70%;
    border-width: 2px;
    border-color: #707070;
  }
  h1 {
    font-family: $f_reg_B;
    @include font-size(2.2);
    text-align: center;
  }
}

@media #{$media_0_991 } {
  section.agency {
    .skills {
      flex-direction: column;
      padding: 0 50px;
      > div {
        width: 100%;
        margin-bottom: 80px;
        p {
          // text-align: justify;
          width: 100%;
        }
      }
    }
  }
}

@media #{$media_0_767 } {
  section.agency {
    .skills {
      padding: 0 20px;
    }
  }
}

/* =================== Vendre =================== */

section.vendre {
  padding-top: 0;
  .header {
    background-image: url("../images/photo-1537726235470-8504e3beef77.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .container-fluid {
      padding: 100px 130px;
      background-color: rgba(58, 58, 58, 0.58);
      .title-container {
        width: 100%;
        h1 {
          color: $white;
        }
        .title-decoration {
          border-color: $white;
          width: 100%;
          .triangle {
            background-color: $white;
          }
        }
      }
      .sub-categories {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: -20px;
        a {
          transition: all ease-in-out 0.1s;
          p {
            color: $white;
            font-family: $f_ch_R;
            @include font-size(1.8);
            border-bottom: 1px solid $accent;
            padding-bottom: 5px;
          }
          &:hover {
            text-decoration: none;
            p {
              color: $accent;
            }
          }
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }
  .content {
    .container-fluid {
      display: block;

      h1 {
        text-align: center;
        font-family: $f_reg_B;
        @include font-size(2.4);
        margin: 80px 0;
      }
      img {
        width: 100%;
      }
      .txt {
        width: 850px;
        margin: auto;
        padding: 50px 0 30px;
        p {
          text-align: center;
          font-family: $f_ch_R;
          @include font-size(1.6);
          strong {
            font-family: $f_ch_B;
          }
          &.mg {
            margin: 90px auto;
          }
        }
        > p {
          margin-bottom: 20px;
        }
        .point {
          margin-bottom: 50px;
          display: flex;
          > p {
            font-family: $f_reg_B;
            @include font-size(7);
            padding: 0 40px 0 140px;
          }
          .pitch {
            h3 {
              margin-top: 40px;
              font-family: $f_reg_B;
              @include font-size(2);
            }
            p {
              text-align: left;
              @include font-size(2);
              line-height: 35px;
            }
          }
        }
      }
    }
  }
  hr {
    margin: 0 auto 100px;
    width: 15%;
    border-width: 1px;
    border-color: #dfdfdf;
  }
}

@media #{$media_0_991 } {
  section.vendre {
    .header {
      .container-fluid {
        padding: 50px 40px;
      }
    }
    .content {
      .container-fluid {
        .txt {
          width: auto;
        }
      }
    }
  }
}

@media #{$media_0_767 } {
  section.vendre {
    .header {
      .container-fluid {
        .sub-categories {
          flex-direction: column;
          padding-top: 50px;
          a {
            transition: all ease-in-out 0.1s;
            p {
              padding: 15px;
              background-color: $accent;
            }
            &:hover {
              p {
                background-color: $white;
                border-color: $white;
              }
            }
          }
        }
      }
    }
    .content {
      .container-fluid {
        h1 {
          @include font-size(2);
        }
        .txt {
          padding-bottom: 0;
          p {
            @include font-size(1.4);
          }
          .point {
            margin-bottom: 50px;
            flex-direction: column;
            > p {
              padding: 0;
              text-align: left;
            }
            .pitch {
              h3 {
                @include font-size(1.7);
              }
              p {
                @include font-size(1.7);
              }
            }
          }
        }
      }
    }
  }
}

/* =================== Footer =================== */

footer {
  background-color: $grey;
  color: $white;
  padding-top: 50px;
  padding-bottom: 50px;
  .container-fluid {
    .footer-content {
      display: flex;
      justify-content: space-between;
      .useful-links {
        img {
          max-width: 50%;
          width: 265px;
          margin-bottom: 30px;
        }
        a {
          color: $white;
          font-family: $f_nt_R;
          text-transform: uppercase;
        }
        > a :first-child {
          display: block;
        }
        .main-links {
          margin-bottom: 5px;
          a {
            @include font-size(1.2);
            margin-right: 15px;
          }
        }
        ul {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 0;
          margin-left: -40px;
          li {
            margin-left: 50px;
            @include font-size(1);
          }
        }
      }
      .infos-contact {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        > a {
          display: block;
          width: 80%;
        }
        p {
          font-family: $f_nt_R;
          @include font-size(1.4);
          a {
            color: $white;
          }
          &:nth-child(1) {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media #{$media_0_767} {
  footer {
    .container-fluid {
      .footer-content {
        flex-wrap: wrap;
        .useful-links {
          width: 100%;
          > a {
            display: none;
          }
          .main-links {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            a {
              @include font-size(1.7);
              display: block;
              text-align: center;
              text-transform: uppercase;
            }
          }
          ul {
            justify-content: center;
            margin: 0;
            li {
              text-transform: uppercase;
              @include font-size(0.9);
              margin-left: 15px;
            }
          }
        }
        .infos-contact {
          align-items: center;
          margin-top: 50px;
          text-align: center;
          width: 100%;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
  }
}

.sub-footer {
  display: none;
  justify-content: center;
  align-items: center;
  background-color: $dark-grey;
  a {
    font-family: $f_nt_R;
    color: darken($white, 50);
    text-decoration: underline;
  }
}

.chrome-browser {
  @media #{$media_0_767} {
    header {
      .container-fluid {
        .header-container {
          > a {
            flex-basis: auto;
          }
        }
      }
    }
  }
}
/* =================== Edge / ie =================== */
.edge,
.ie {
  section.product-detail {
    .content {
      .details {
        .energy-container {
          > div {
            img {
              align-self: flex-start;
            }
            &.electricity {
              img {
                align-self: flex-end;
              }
            }
          }
        }
      }
    }
  }
}

/* ================ partenaires ================ */

.liste-actu {
  .content {
    .une-actu {
      display: flex;
      justify-content: space-between;
      margin-bottom: 60px;
      transition: all ease-in-out 0.1s;
      img {
        width: 25%;
        margin-right: 30px;
        height: min-content;
      }
      .infos-actu {
        flex: 1;
        * {
          color: $grey;
          font-family: $f_ch_R;
        }
        h2 {
          font-family: $f_ch_B;
        }
      }
      &:hover {
        text-decoration: none;
        h2 {
          color: $accent;
        }
      }
    }
  }
}

/* ================ 404 ================ */

.error404 {
  font-family: $f_ch_B;
  padding: 50px 0 100px;
  .erreur {
    @include font-size(16);
  }
  a {
    border: 1px solid #707070;
    text-transform: uppercase;
    padding: 15px;
    color: #3a3a3a;
    margin-top: 30px;
    display: inline-block;
    transition: all ease-in-out 0.1s;
    &:hover {
      background-color: #3a3a3a;
      color: #fff;
      text-decoration: none;
    }
  }
}