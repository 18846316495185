@font-face {
  font-family: "charlevoix_problack";
  src: url("../fonts/charlevoix/charlevoixpro-black-winners.eot");
  src: url("../fonts/charlevoix/charlevoixpro-black-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/charlevoix/charlevoixpro-black-winners.woff2") format("woff2"),
    url("../fonts/charlevoix/charlevoixpro-black-winners.woff") format("woff"),
    url("../fonts/charlevoix/charlevoixpro-black-winners.ttf") format("truetype"),
    url("../fonts/charlevoix/charlevoixpro-black-winners.svg#charlevoix_problack") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "charlevoix_probold";
  src: url("../fonts/charlevoix/charlevoixpro-bold-winners.eot");
  src: url("../fonts/charlevoix/charlevoixpro-bold-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/charlevoix/charlevoixpro-bold-winners.woff2") format("woff2"),
    url("../fonts/charlevoix/charlevoixpro-bold-winners.woff") format("woff"),
    url("../fonts/charlevoix/charlevoixpro-bold-winners.ttf") format("truetype"),
    url("../fonts/charlevoix/charlevoixpro-bold-winners.svg#charlevoix_probold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "charlevoix_proextrabold";
  src: url("../fonts/charlevoix/charlevoixpro-extrabold-winners.eot");
  src: url("../fonts/charlevoix/charlevoixpro-extrabold-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/charlevoix/charlevoixpro-extrabold-winners.woff2") format("woff2"),
    url("../fonts/charlevoix/charlevoixpro-extrabold-winners.woff") format("woff"),
    url("../fonts/charlevoix/charlevoixpro-extrabold-winners.ttf") format("truetype"),
    url("../fonts/charlevoix/charlevoixpro-extrabold-winners.svg#charlevoix_proextrabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "charlevoix_prolight";
  src: url("../fonts/charlevoix/charlevoixpro-light-winners.eot");
  src: url("../fonts/charlevoix/charlevoixpro-light-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/charlevoix/charlevoixpro-light-winners.woff2") format("woff2"),
    url("../fonts/charlevoix/charlevoixpro-light-winners.woff") format("woff"),
    url("../fonts/charlevoix/charlevoixpro-light-winners.ttf") format("truetype"),
    url("../fonts/charlevoix/charlevoixpro-light-winners.svg#charlevoix_prolight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "charlevoix_promedium";
  src: url("../fonts/charlevoix/charlevoixpro-medium-winners.eot");
  src: url("../fonts/charlevoix/charlevoixpro-medium-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/charlevoix/charlevoixpro-medium-winners.woff2") format("woff2"),
    url("../fonts/charlevoix/charlevoixpro-medium-winners.woff") format("woff"),
    url("../fonts/charlevoix/charlevoixpro-medium-winners.ttf") format("truetype"),
    url("../fonts/charlevoix/charlevoixpro-medium-winners.svg#charlevoix_promedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "charlevoix_proregular";
  src: url("../fonts/charlevoix/charlevoixpro-regular-winners.eot");
  src: url("../fonts/charlevoix/charlevoixpro-regular-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/charlevoix/charlevoixpro-regular-winners.woff2") format("woff2"),
    url("../fonts/charlevoix/charlevoixpro-regular-winners.woff") format("woff"),
    url("../fonts/charlevoix/charlevoixpro-regular-winners.ttf") format("truetype"),
    url("../fonts/charlevoix/charlevoixpro-regular-winners.svg#charlevoix_proregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "charlevoix_prothin";
  src: url("../fonts/charlevoix/charlevoixpro-thin-winners.eot");
  src: url("../fonts/charlevoix/charlevoixpro-thin-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/charlevoix/charlevoixpro-thin-winners.woff2") format("woff2"),
    url("../fonts/charlevoix/charlevoixpro-thin-winners.woff") format("woff"),
    url("../fonts/charlevoix/charlevoixpro-thin-winners.ttf") format("truetype"),
    url("../fonts/charlevoix/charlevoixpro-thin-winners.svg#charlevoix_prothin") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaregular";
  src: url("../fonts/helvetica/helvetica-winners.eot");
  src: url("../fonts/helvetica/helvetica-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica/helvetica-winners.woff2") format("woff2"),
    url("../fonts/helvetica/helvetica-winners.woff") format("woff"),
    url("../fonts/helvetica/helvetica-winners.ttf") format("truetype"),
    url("../fonts/helvetica/helvetica-winners.svg#helveticaregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticabold";
  src: url("../fonts/helvetica/helvetica-bold-winners.eot");
  src: url("../fonts/helvetica/helvetica-bold-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica/helvetica-bold-winners.woff2") format("woff2"),
    url("../fonts/helvetica/helvetica-bold-winners.woff") format("woff"),
    url("../fonts/helvetica/helvetica-bold-winners.ttf") format("truetype"),
    url("../fonts/helvetica/helvetica-bold-winners.svg#helveticabold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticabold_oblique";
  src: url("../fonts/helvetica/helvetica-boldoblique-winners.eot");
  src: url("../fonts/helvetica/helvetica-boldoblique-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica/helvetica-boldoblique-winners.woff2") format("woff2"),
    url("../fonts/helvetica/helvetica-boldoblique-winners.woff") format("woff"),
    url("../fonts/helvetica/helvetica-boldoblique-winners.ttf") format("truetype"),
    url("../fonts/helvetica/helvetica-boldoblique-winners.svg#helveticabold_oblique") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticacompressed";
  src: url("../fonts/helvetica/helvetica-compressed-winners.eot");
  src: url("../fonts/helvetica/helvetica-compressed-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica/helvetica-compressed-winners.woff2") format("woff2"),
    url("../fonts/helvetica/helvetica-compressed-winners.woff") format("woff"),
    url("../fonts/helvetica/helvetica-compressed-winners.ttf") format("truetype"),
    url("../fonts/helvetica/helvetica-compressed-winners.svg#helveticacompressed") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaoblique";
  src: url("../fonts/helvetica/helvetica-oblique-winners.eot");
  src: url("../fonts/helvetica/helvetica-oblique-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica/helvetica-oblique-winners.woff2") format("woff2"),
    url("../fonts/helvetica/helvetica-oblique-winners.woff") format("woff"),
    url("../fonts/helvetica/helvetica-oblique-winners.ttf") format("truetype"),
    url("../fonts/helvetica/helvetica-oblique-winners.svg#helveticaoblique") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helvetica_roundedbold";
  src: url("../fonts/helvetica/helvetica-rounded-bold-winners.eot");
  src: url("../fonts/helvetica/helvetica-rounded-bold-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/helvetica/helvetica-rounded-bold-winners.woff2") format("woff2"),
    url("../fonts/helvetica/helvetica-rounded-bold-winners.woff") format("woff"),
    url("../fonts/helvetica/helvetica-rounded-bold-winners.ttf") format("truetype"),
    url("../fonts/helvetica/helvetica-rounded-bold-winners.svg#helvetica_roundedbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "optician_sansregular";
  src: url("../fonts/optician-sans/optician-sans-winners.eot");
  src: url("../fonts/optician-sans/optician-sans-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/optician-sans/optician-sans-winners.woff2") format("woff2"),
    url("../fonts/optician-sans/optician-sans-winners.woff") format("woff"),
    url("../fonts/optician-sans/optician-sans-winners.ttf") format("truetype"),
    url("../fonts/optician-sans/optician-sans-winners.svg#optician_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "regattia_stencilbold";
  src: url("../fonts/regattia/regattiastencil-bold-winners.eot");
  src: url("../fonts/regattia/regattiastencil-bold-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/regattia/regattiastencil-bold-winners.woff2") format("woff2"),
    url("../fonts/regattia/regattiastencil-bold-winners.woff") format("woff"),
    url("../fonts/regattia/regattiastencil-bold-winners.ttf") format("truetype"),
    url("../fonts/regattia/regattiastencil-bold-winners.svg#regattia_stencilbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "regattiabold";
  src: url("../fonts/regattia/regattia-bold-winners.eot");
  src: url("../fonts/regattia/regattia-bold-winners.eot?#iefix") format("embedded-opentype"),
    url("../fonts/regattia/regattia-bold-winners.woff2") format("woff2"),
    url("../fonts/regattia/regattia-bold-winners.woff") format("woff"),
    url("../fonts/regattia/regattia-bold-winners.ttf") format("truetype"),
    url("../fonts/regattia/regattia-bold-winners.svg#regattiabold") format("svg");
  font-weight: normal;
  font-style: normal;
}



@font-face {
  font-family: 'nevisreg';
  src: url('../fonts/nevis/nevis-winners.eot');
  src: url('../fonts/nevis/nevis-winners.eot?#iefix') format('embedded-opentype'),
       url('../fonts/nevis/nevis-winners.woff2') format('woff2'),
       url('../fonts/nevis/nevis-winners.woff') format('woff'),
       url('../fonts/nevis/nevis-winners.ttf') format('truetype'),
       url('../fonts/nevis/nevis-winners.svg#webfontbold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'nunitobold';
  src: url('../fonts/nunito/nunito-bold-winners.eot');
  src: url('../fonts/nunito/nunito-bold-winners.eot?#iefix') format('embedded-opentype'),
       url('../fonts/nunito/nunito-bold-winners.woff2') format('woff2'),
       url('../fonts/nunito/nunito-bold-winners.woff') format('woff'),
       url('../fonts/nunito/nunito-bold-winners.ttf') format('truetype'),
       url('../fonts/nunito/nunito-bold-winners.svg#nunitobold') format('svg');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'nunitoregular';
  src: url('../fonts/nunito/nunito-regular-winners.eot');
  src: url('../fonts/nunito/nunito-regular-winners.eot?#iefix') format('embedded-opentype'),
       url('../fonts/nunito/nunito-regular-winners.woff2') format('woff2'),
       url('../fonts/nunito/nunito-regular-winners.woff') format('woff'),
       url('../fonts/nunito/nunito-regular-winners.ttf') format('truetype'),
       url('../fonts/nunito/nunito-regular-winners.svg#nunitoregular') format('svg');
  font-weight: normal;
  font-style: normal;

}
