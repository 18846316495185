/* font-family */

//charlevoix
$f_ch_Bk: "charlevoix_problack", serif;
$f_ch_B: "charlevoix_probold", serif;
$f_ch_EB: "charlevoix_proextrabold", serif;
$f_ch_L: "charlevoix_prolight", serif;
$f_ch_M: "charlevoix_promedium", serif;
$f_ch_R: "charlevoix_proregular", serif;
$f_ch_T: "charlevoix_prothin", serif;

//helvetica
$f_hl_R: "helveticaregular", sans-serif;
$f_hl_B: "helveticabold", sans-serif;
$f_hl_BO: "helveticabold_oblique", sans-serif;
$f_hl_C: "helveticacompressed", sans-serif;
$f_hl_O: "helveticaoblique", sans-serif;
$f_hl_RB: "helvetica_roundedbold", sans-serif;

//optician
$f_op_R: "optician_sansregular", sans-serif;

//regattia
$f_reg_st_B: "regattia_stencilbold", sans-serif;
$f_reg_B: "regattiabold", sans-serif;

//nevis
$f_nv_R: "nevisreg", sans-serif;

//nunito
$f_nt_R: "nunitobold", sans-serif;
$f_nt_B: "nunitoregular", sans-serif;

/* font size */

$font_size_nav: 15px;

/* colors */

$dark_grey: #2c2c2c;
$dark_grey_transparent: rgba(44, 44, 44, 0.6);
$grey: #3a3a3a;
$light_grey: #666;
$gold: rgb(230, 168, 26);
$dark_gold: #af6d20;
$p_white: #fff;
$white:#fff;
$accent: #8d1209;
$transparent: rgba(0, 0, 0, 0);
$degrade_focus: inset 0 1px 1px rgba(0, 0, 0, 0.075),
0 0 8px rgba(216, 136, 42, 0.6);

/* transparences */

$background_hover: rgba(1, 1, 1, 0.1);

/* background images */

$bgImg_flag_fr: url(/images/flag_fr.jpg);
$bgImg_flag_es: url(/images/flag_es.jpg);
$bgImg_flag_en: url(/images/flag_en.jpg);



/* break media queries */

$media_1200: "screen and (min-width:1200px)";
$media_992_1199: "screen and (min-width:992px) and (max-width:1199px)";
$media_768_991: "screen and (min-width:768px) and (max-width:991px)";
$media_480_767: "screen and (min-width:480px) and (max-width:767px)";
$media_320_479: "screen and (min-width:320px) and (max-width:479px)";
$media_0_1199: "screen and (max-width:1199px)";
$media_0_991: "screen and (max-width:991px)";
$media_0_767: "screen and (max-width:767px)";
$media_0_359: "screen and (max-width:359px)";
$media_0_319: "screen and (max-width:319px)";

/* mixins */

@mixin font-size($sizeValue) {
    $remValue: $sizeValue;
    $pxValue: ($sizeValue * 10);
    font-size: $remValue+rem;
    font-size: $pxValue+px; 
}


@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

@mixin display-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@mixin flex-direction {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@mixin justify-content {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin align-items {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@mixin transition_radio {
    -webkit-transition: background-color 0.4s linear;
    -o-transition: background-color 0.4s linear;
    -moz-transition: background-color 0.4s linear;
    transition: background-color 0.4s linear;
}